<template>
  <div class="feedback d-none">
    <div class="feedback-header">
      <h1>See Why our Customers
        Love us</h1>
    </div>
    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">

      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="2000">

          <div class="carousel-caption d-block">

            <p>1 I am extremely satisfied with the online car service. From the moment I booked the appointment,
              the process was smooth and hassle-free. The team was professional, courteous, and efficient,
              ensuring clear communication at every step. They quickly diagnosed the issue with my car.</p>

            <img src="./feed-back-img/feedback.png ">

            <div class="icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
            </div>
            <div class="clint_name">
              <h2>Mr. Tony Rutherford</h2>
              <p>Product Quality Engineer</p>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">

          <div class="carousel-caption d-block">

            <p>1 I am extremely satisfied with the online car service. From the moment I booked the appointment,
              the process was smooth and hassle-free. The team was professional, courteous, and efficient,
              ensuring clear communication at every step. They quickly diagnosed the issue with my car.</p>

            <img src="./feed-back-img/feedback.png ">

            <div class="icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M14.1699 2.5549C14.4312 1.75073 15.5688 1.75072 15.8301 2.5549L18.1717 9.76161C18.2886 10.1213 18.6237 10.3647 19.0019 10.3647H26.5795C27.425 10.3647 27.7766 11.4468 27.0925 11.9438L20.9621 16.3978C20.6562 16.62 20.5282 17.014 20.645 17.3736L22.9866 24.5804C23.2479 25.3845 22.3275 26.0532 21.6435 25.5562L15.5131 21.1022C15.2071 20.88 14.7929 20.88 14.4869 21.1022L8.35655 25.5562C7.67248 26.0532 6.75207 25.3845 7.01336 24.5804L9.35496 17.3736C9.47181 17.014 9.3438 16.62 9.03788 16.3978L2.90748 11.9438C2.22341 11.4468 2.57497 10.3647 3.42053 10.3647H10.9981C11.3763 10.3647 11.7114 10.1213 11.8283 9.76161L14.1699 2.5549Z"
                    fill="#F99F00"/>
              </svg>
            </div>
            <div class="clint_name">
              <h2>Mr. Tony Rutherford</h2>
              <p>Product Quality Engineer</p>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"
              data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  </div>
</template>

<script>


</script>

<style scoped>
.feedback {
  height: 100vh;
  width: 100%;
  padding: 80px;
}

.feedback-header {
  margin-bottom: 50px;
}

.feedback-header h1 {
  color: #691209;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 76.8px */
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.carousel-caption > p {

  color: #1E1E1E;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 200% */
  margin-bottom: 80px;

}

.carousel-item {
  height: 600px;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: -4rem !important;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  height: 100%;
}
.carousel-caption p {
  text-align: justify;
}
.carousel-caption > img {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: 2.25px solid #FFF;
  margin-bottom: 40px;
}

.clint_name h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px; /* 100% */
  margin-bottom: 16px;
}

.clint_name p {
  color: #050505;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  margin-bottom: 0;
}

.carousel-inner {
  height: 37.5rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.carousel-caption > p::before {
  content: open-quote;
  top: -60px;
  left: 10px;
  font-size: 5rem;
  font-weight: 800;
  line-height: 1;
  position: absolute;
  color: #c7c7c7;
  height: 40px;
  z-index: -1;
  font-family: sans-serif;
}

.carousel-caption > p::after {
  content: close-quote;
  top: 5rem;
  right: 10px;
  font-size: 5rem;
  font-weight: 800;
  line-height: 1;
  position: absolute;
  color: #c7c7c7;
  height: 40px;
  z-index: -1;
  font-family: sans-serif;
}

@media only screen and (max-width: 599px) {
  .feedback {
    padding: 20px;
  }
  .feedback-header h1 {
    font-size: 2rem;
  }
  .carousel-caption > p::after {
    content: close-quote;
    top: 135px;
    right: 10px;
  }
  .carousel-caption img {
    height: 5rem;
    width: 5rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .carousel-caption > p::after {
    content: close-quote;
    top: 135px;
    right: 10px;
  }
  .carousel-caption img {
    height: 7rem;
    width: 7rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .carousel-caption > p::after {
    content: close-quote;
    top: 135px;
    right: 10px;
  }

}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .carousel-caption > p::after {
    content: close-quote;
    top: 100px;
    right: 10px;
  }
}

</style>