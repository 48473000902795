<template>
  <div class="varification-bg">
    <div class="varification">
      <div class="varification-logo">
        <img src="./Varification-image/varification-logo.png" alt="Verification Logo">
      </div>
      <div class="varification-title">
        <h1>Verification Code</h1>
        <p>Please check your phone. We’ve sent a code to </p>
        <div class="varification-gmail-link">
          <h2>{{ user_data.phone_number }}</h2>
          <!--          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
          <!--            <path d="M9.16493 13.6286H14M7.34722 4.00059L10.982 6.79201M8.52001 2.52986C9.0371 1.91186 9.96666 1.82124 10.5975 2.32782C10.6324 2.35531 11.753 3.22586 11.753 3.22586C12.446 3.64479 12.6613 4.5354 12.2329 5.21506C12.2102 5.25146 5.87463 13.1763 5.87463 13.1763C5.66385 13.4393 5.34389 13.5945 5.00194 13.5982L2.02902 14.1447V11.3149C1.95244 10.9895 2.02902 10.6478 2.2398 10.3849L8.52001 2.52986Z" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--          </svg>-->
        </div>
      </div>
      <form @submit.prevent="verifyCode">
        <div class="varification-code d-flex align-items-center justify-content-center gap-3">
          <input v-for="(digit, index) in code" :key="index" :ref="`input-${index}`" class="code-num" type="text"
                 maxlength="1" v-model="code[index]" @keydown="handleKeyDown(index, $event)"/>
        </div>
        <input class="varification-btn" type="submit" value="Verify">
        <div class="form-error" v-if="showError">{{ errorMessage }}</div>
      </form>
      <div class="any-account">
        <div v-if="showTimer">
          <h1>Send code again<span> {{ minutes }}:{{ seconds }} sec</span></h1>
        </div>
        <button v-if="showResendButton" @click="resendCode">Resend Code</button>
      </div>
      <p class="keebabi-footer">Thank you for choosing <span>Keebabi!</span></p>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  props: ['user'],
  data() {
    return {
      code: Array(6).fill(''),
      user_data: JSON.parse(this.user),
      timer: 120,
      showTimer: true,
      showResendButton: false,
    };
  },
  created() {
    console.log(this.user_data)
  },
  computed: {
    minutes() {
      return String(Math.floor(this.timer / 60)).padStart(2, '0');
    },
    seconds() {
      return String(this.timer % 60).padStart(2, '0');
    }
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.showTimer = false;
          this.showResendButton = true;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    async resendCode() {
      // Add your logic to resend the code
      alert('Resend code logic here!');
    },
    focusNext(index) {
      if (this.code[index].length === 1 && index < this.code.length - 1) {
        this.$refs[`input-${index + 1}`][0].focus();
      } else if (this.code[index] === '' && index > 0) {
        this.$refs[`input-${index - 1}`][0].focus();
      }
    },
    handleKeyDown(index, event) {
      if (event.key === 'Backspace' && index > 0 && this.code[index] === '') {
        this.$refs[`input-${index - 1}`][0].focus();
        event.preventDefault();
      } else if (event.key >= '0' && event.key <= '9' && this.code[index] === '' && index < this.code.length - 1) {
        this.code[index] = event.key;
        this.$refs[`input-${index + 1}`][0].focus();
        event.preventDefault();
      }
    },


    async verifyCode() {
      try {
        const csrftoken = this.getCookie('csrftoken');
        const formData = new FormData();
        formData.append('code', this.code.join(''));
        const response = await fetch(`/verify-code-forget-password/${this.user_data.id}/`, {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrftoken, // Include CSRF token in the header
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            window.location.href = `/reset-password/?phone_number=${data.phone_number}&code=${data.code}`;
          } else {
            this.showError = true;
            this.errorMessage = 'Verification code is incorrect.';
          }
        } else {
          this.showError = true;
          this.errorMessage = 'Verification code is incorrect.';
        }
      } catch (error) {
        console.error('Error verifying code:', error.message);
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },

  },
  setup() {
    const showError = ref(false);
    const errorMessage = ref('');

    return {
      showError,
      errorMessage
    };
  }
};
</script>


<style>
.form-error {
  display: block;
  color: red;
  margin-top: 10px;
}

.varification-bg {
  background: url(./Varification-image/varification-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 0;
  height: 100vh;

}

.varification {
  width: 500px;
}

.varification-logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
}

.varification-title {
  margin-bottom: 48px;
}

.varification-title h1 {
  color: #1E1E1E;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 133.333% */
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

.varification-title p {
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  letter-spacing: 0.42px;
}

.varification-gmail-link {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.varification-gmail-link h2 {
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.42px;
}

.varification-code {
  margin-bottom: 60px;
}

.code-num {
  width: 70px;
  text-align: center;
  padding: 18px;
  border-radius: 15px;
  border: 1px solid #D8DADC;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 40px */
  outline: none;
}

.code-num::-webkit-inner-spin-button,
.code-num::-webkit-outer-spin-button {
  display: none;
}


.remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 12px;
}

.remember h4 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.remember-forget h2 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  margin-bottom: 0;
}

.varification-btn {
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  padding: 14px;
  margin-bottom: 40px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.54px;
  width: 100%;
  text-align: center;
}

.any-account {
  margin-bottom: 60px;
}

.any-account h1 {
  color: #494949;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  margin-bottom: 0;
}

.any-account h1 span {
  color: #FF6B00;
}

.keebabi-footer {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 85.714% */
  letter-spacing: 2.8px;
  margin-bottom: 0;
}

.keebabi-footer span {
  color: #691209;
}
</style>