<template>
  <form @submit.prevent="handleSubmit" class="row card-details w-100" style="gap: 30px;">
    <div class="col-12">
      <label>Card Number</label>
      <input class="w-100" type="text" v-model="cardNumber" placeholder="1234 5678 9012 3456" />
    </div>
    <div class="col-12">
      <div class="d-flex flex-column flex-md-row gap-2">
        <div class="w-100 w-md-50">
          <label>Expiry Date</label>
          <input class="w-100" type="text" v-model="expDate" placeholder="MM/YY" />
        </div>
        <div class="w-100 w-md-50">
          <label>CVC</label>
          <input class="w-100" type="password" v-model="cvc" placeholder="***" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <label>Card Holder Name</label>
      <input class="w-100" type="text" v-model="name" placeholder="Enter card holder name" />
    </div>
    <div class="col-12">
      <button type="submit">Pay Now</button>
    </div>
  </form>
</template>

<script>
import Stripe from 'stripe';

export default {
  props: [
    'cart_id',
    'selectedPaymentMethod',
    'total',
    'address',
    'loggedIn',
    'discount',
    'reward_discount',
    'coupon_discount',
    'delivery_fee',
    'coupon',
    'stripe',
    'phoneNumber'
  ],
  data() {
    return {
      cardNumber: '',
      expDate: '',
      cvc: '',
      name: ''
    };
  },
  methods: {
    async handleSubmit() {
      // Validate address and phone number
      if(this.address) {
        console.log(this.address)
      }
      if (!this.address || this.address === 'None') {
        this.$toast.error('Please provide a valid address.');
        return;
      }

      if (this.loggedIn === 'False' && (!this.phoneNumber || this.phoneNumber.trim() === '')) {
        this.$toast.error('Please enter a valid phone number.');
        return;
      }

      const stripe = Stripe(this.stripe);
      const token = await stripe.tokens.create({
        card: {
          number: this.cardNumber,
          exp_month: this.expDate.split('/')[0],
          exp_year: this.expDate.split('/')[1],
          cvc: this.cvc
        }
      });

      this.processPayment(token);
    },
    async processPayment(token) {
      const csrftoken = this.getCookie('csrftoken');

      try {
        const response = await fetch('../pay-now/?type=' + this.selectedPaymentMethod, {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrftoken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: token.id,
            cart_id: this.cart_id,
            address: this.address,
            coupon: this.coupon,
            amount: this.total,
            discount: this.discount,
            reward_discount: this.reward_discount,
            coupon_discount: this.coupon_discount,
            delivery_fee: this.delivery_fee,
            payment_method: this.selectedPaymentMethod,
            phone_number: this.loggedIn === 'False' ? this.phoneNumber : null,
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Payment successful, handle success
            window.location.href = `/process/?type=${this.selectedPaymentMethod}&paymentId=${data.charge.id}&payer_id=${data.charge.id}`;
          } else {
            this.$toast.error('Payment failed. Please refresh the page and try again.');
          }
        } else {
          this.$toast.error('An error occurred. Please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        this.$toast.error('An error occurred. Please refresh the page and try again.');
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    }
  }
};
</script>

<style>
.card-details label{
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
input{
  padding: 12px;
}
.card-details input{
  border-radius: 10px;
  background: #435971;
}
.card-details input:hover{
  background: #435971;
}
input::placeholder{
  color: #97A2B0 !important;
  opacity: 1;
}

button{
    padding: 15px;
    width: 100%;
    color: white;
    border: 2px solid white;
    border-radius: 15px;
    background: #ffa700b5;
    font-size: 14px;
    font-weight: 600;
    transition: 0.5s;
}
button:hover{
  background: #ffa700;
}


</style>