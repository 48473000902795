<template>
  <div class="food-delivery">
    <div class="food-delivery-left row m-0">
      <div class="col-12 col-sm-6 d-flex justify-content-center img-one">
        <img src="./home-converted/Delivery1.webp" alt="">
      </div>

      <div class=" col-12 col-sm-6 img-two">
        <img src="./home-converted/Delivery2.webp" alt="">

        <img src="./Food-Delivery-section-image/fastest-delivery.jpg" alt="" class="rounded-3">
      </div>
    </div>
    <div class="food-delivery-right">
      <div class="food-delivery-right-header">
        <h1>Fastest <span>Food</span> <br> Delivery In City</h1>
        <p>Best restaurants, order delicious meals and enjoy hassle-free delivery, all in one app.</p>
      </div>
      <div class="food-delivery-process">
        <div class="process">
          <img src="./Food-Delivery-section-image/delivery-process-icon-one.png" alt="">
          <p> Fast delivery within 30 min</p>
        </div>
        <div class="process">
          <img src="./Food-Delivery-section-image/delivery-process-icon-two.png" alt="">
          <p> Best Offer & Prices</p>
        </div>
        <div class="process">
          <img src="./Food-Delivery-section-image/delivery-process-icon-three.png" alt="">
          <p>Easy ordering process</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [],
};
</script>
<style>
.food-delivery {
  padding: 160px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.food-delivery-left .img-one img {
  margin-bottom: 20px;
  width: 387px;
}

.food-delivery-left .img-two img {
  margin-top: 26px;
  width: 261px;
}


.food-delivery-right .food-delivery-right-header h1 {
  color: #4A4A4A;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.food-delivery-right-header h1 span {
  color: #F99F00;
}

.food-delivery-right-header p {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  margin-bottom: 50px;
}

.process {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.process p {
  margin-bottom: 5px;
  color: #4A4A4A;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 100% */
}
@media only screen and (max-width: 599px){

 .food-delivery {
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
 }
 .food-delivery-left .img-one img {
    width: 252px;
 }
 .food-delivery-left .img-two img {
    width: 250px;
 }

 .food-delivery-right{
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
 }
   .food-delivery-right .food-delivery-right-header h1 {
    color: #4A4A4A;
    font-size: 28px;
    }

}
@media only screen and (min-width: 600px) and (max-width: 991px){

 .food-delivery {
    padding: 40px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
 }
 .food-delivery-left .img-one img {
    width: 252px;
 }
 .food-delivery-left .img-two img {
    width: 250px;
 }

 .food-delivery-right{
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
 }


}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .food-delivery {
    padding: 160px 40px;
  }

  .food-delivery-left {
    width: 100%;
  }

  .food-delivery-left .img-one img {
    width: 278px;
  }

  .food-delivery-left .img-two img {
    width: 230px;
  }

  .food-delivery-right .food-delivery-right-header h1 {
    font-size: 40px;
  }

  .process p {
    font-size: 16px;
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .food-delivery-left .img-one img {
    width: 350px;
  }

  .food-delivery-left {
    width: 80%;
  }

  .food-delivery-left .img-two img {

    width: 240px;
  }
}
</style>
