<template>
  <div class="sign-bg" id="signupForm">
    <div class="sign">
      <div class="sign-logo">
        <img src="./Signup-image/signup-logo.png" alt="">
      </div>

      <form @submit.prevent="handleSubmit">

        <h1 class="sign-title">Sign Up For <br> Keebabi <span> Rewards</span></h1>

        <div class="sign-full-name signup">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
            <path
                d="M12.5007 10.9167C14.8018 10.9167 16.6673 9.0512 16.6673 6.75001C16.6673 4.44882 14.8018 2.58334 12.5007 2.58334C10.1995 2.58334 8.33398 4.44882 8.33398 6.75001C8.33398 9.0512 10.1995 10.9167 12.5007 10.9167Z"
                fill="#691209"/>
            <path
                d="M20.8337 18.7292C20.8337 21.3177 20.8337 23.4167 12.5003 23.4167C4.16699 23.4167 4.16699 21.3177 4.16699 18.7292C4.16699 16.1406 7.89824 14.0417 12.5003 14.0417C17.1024 14.0417 20.8337 16.1406 20.8337 18.7292Z"
                fill="#691209"/>
          </svg>
          <input type="text" v-model="formData.fullName" placeholder="Full Name">
        </div>

        <div class="sign-location signup">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clip-path="url(#clip0_276_2766)">
              <path
                  d="M20.8422 8.48889L24.7204 12.0333C25.189 12.4677 25.0552 12.8025 24.4197 12.8025H21.5108V22.0631C21.5108 22.6986 20.9759 23.2667 20.3404 23.2667H15.2918V16.7802C15.2918 16.1447 14.7237 15.5766 14.0882 15.5766H10.9118C10.2763 15.5766 9.70823 16.1447 9.70823 16.7802V23.2667H4.65961C4.02412 23.2667 3.45603 22.6986 3.45603 22.0631V12.8025H0.580271C-0.0552193 12.8025 -0.18895 12.4677 0.279644 12.0333L11.6136 1.736C12.0822 1.30164 12.8846 1.30164 13.3853 1.736L15.9594 4.04259V2.20352C15.9594 1.56803 16.5275 0.999939 17.1629 0.999939H19.6707C20.3062 0.999939 20.8411 1.56803 20.8411 2.20352L20.8422 8.48889Z"
                  fill="#691209"/>
            </g>
            <defs>
              <clipPath id="clip0_276_2766">
                <rect width="25" height="25" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <input type="text" v-model="formData.address" placeholder="Address">
        </div>

        <div class="sign-email signup">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.88372 2.00006C4.23119 2.00006 2.73685 2.41308 1.65221 3.42024C0.557286 4.43696 0 5.94681 0 7.88378V15.0466C0 16.9836 0.557286 18.4934 1.65221 19.5101C2.73685 20.5173 4.23119 20.9303 5.88372 20.9303H16.1163C17.7688 20.9303 19.2632 20.5173 20.3477 19.5101C21.4427 18.4934 22 16.9836 22 15.0466V7.88378C22 5.94681 21.4427 4.43696 20.3477 3.42024C19.2632 2.41308 17.7688 2.00006 16.1163 2.00006H5.88372ZM18.3966 7.74288C18.7319 7.48373 18.7937 7.00176 18.5345 6.66637C18.2755 6.33098 17.7935 6.26919 17.4581 6.52835L11.782 10.9143C11.3214 11.2702 10.6785 11.2702 10.2178 10.9143L4.54182 6.52835C4.20643 6.26919 3.72447 6.33098 3.46531 6.66637C3.20615 7.00176 3.26794 7.48373 3.60333 7.74288L9.2794 12.1289C10.2928 12.9119 11.7071 12.9119 12.7205 12.1289L18.3966 7.74288Z"
                  fill="#691209"/>
          </svg>
          <input type="email" v-model="formData.email" placeholder="Email">
        </div>

        <div class="sign-phone signup">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
                d="M21.8935 22.9999C21.8376 22.9999 21.7826 22.9981 21.7267 22.9935C11.241 22.1813 2.76281 13.7077 2.00565 3.28346C1.98457 2.99491 2.02264 2.70509 2.11753 2.43177C2.21242 2.15845 2.36213 1.90739 2.55748 1.69397C2.74812 1.48561 2.9799 1.31905 3.23819 1.20482C3.49647 1.09059 3.77564 1.03117 4.05805 1.03031L8.36819 1.00006C9.32518 1.00006 10.1529 1.64264 10.3839 2.56205L11.2841 6.13794C11.384 6.5328 11.3642 6.94844 11.2273 7.33203C11.0903 7.71561 10.8424 8.0498 10.515 8.2921L8.78344 9.58184C10.3027 12.3479 12.572 14.629 15.3302 16.1625L16.6685 14.3897C16.9136 14.0645 17.249 13.8187 17.633 13.6831C18.0169 13.5474 18.4323 13.528 18.8273 13.6271L22.4279 14.5208C22.8763 14.6306 23.2749 14.8876 23.5599 15.2507C23.8449 15.6138 23.9999 16.0621 24 16.5237V20.9365C24 21.5122 23.7672 22.0448 23.3437 22.4371C22.9479 22.7998 22.4304 23.0007 21.8935 22.9999Z"
                fill="#691209"/>
          </svg>
          <input type="text" v-model="formData.phone" placeholder="Phone">
        </div>

        <div class="sign-pass signup">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
            <path
                d="M12.4838 2.58333C15.6804 2.58333 18.2525 5.09873 18.2525 8.20417V9.8014C20.0466 10.3614 21.3538 11.9856 21.3538 13.9254V19.068C21.3538 21.4696 19.3628 23.4167 16.9082 23.4167H8.09217C5.6365 23.4167 3.64551 21.4696 3.64551 19.068V13.9254C3.64551 11.9856 4.95379 10.3614 6.74685 9.8014V8.20417C6.75743 5.09873 9.32953 2.58333 12.4838 2.58333ZM12.4944 14.442C11.9863 14.442 11.5735 14.8457 11.5735 15.3425V17.6406C11.5735 18.1478 11.9863 18.5515 12.4944 18.5515C13.013 18.5515 13.4258 18.1478 13.4258 17.6406V15.3425C13.4258 14.8457 13.013 14.442 12.4944 14.442ZM12.505 4.39483C10.3563 4.39483 8.60977 6.09247 8.59918 8.18346V9.57677H16.4002V8.20417C16.4002 6.10282 14.6537 4.39483 12.505 4.39483Z"
                fill="#691209"/>
          </svg>
          <input type="password" v-model="formData.password" placeholder="Password">
        </div>

        <div class="remember-forget">
          <div class="remember form-check">
            <input type="checkbox" v-model="formData.agreeToTerms">
            <h4>I agree to the Kee terms and conditions and Privacy Policy</h4>
          </div>
        </div>

        <div class="sign-btn">
          <input type="submit" value="Sign Up">
        </div>
        <div class="form-error" v-if="showError">{{ errorMessage }}</div>

        <div class="any-account">
          <h1>Already have an account?<span><a href="../login"> Login!</a></span></h1>
        </div>

      </form>
      <p class="keebabi-footer">Thank you for choosing <span>Keebabi!</span></p>
    </div>
  </div>

</template>

<script>
import {reactive, ref} from 'vue';

export default {
  setup() {
    const formData = reactive({
      fullName: '',
      address: '',
      email: '',
      phone: '',
      password: '',
      agreeToTerms: false
    });
    const showError = ref(false);
    const errorMessage = ref('');

    const getCSRFToken = () => {
      const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
      return csrfToken;
    };

    const handleSubmit = () => {
      if (formData.agreeToTerms) {


        const formDataObject = new FormData();
        formDataObject.append('username', formData.phone);
        formDataObject.append('first_name', formData.fullName);

        formDataObject.append('address', formData.address);
        formDataObject.append('email', formData.email);
        formDataObject.append('phone_number', formData.phone);
        formDataObject.append('password1', formData.password);
        formDataObject.append('password2', formData.password);
        formDataObject.append('agreeToTerms', formData.agreeToTerms);
        const csrfToken = getCSRFToken();

        // Send formData to current URL using fetch API
        fetch(window.location.href, {
          method: 'POST',
          body: formDataObject,
          headers: {
            'X-CSRFToken': csrfToken
          }
        })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json(); // Parse response JSON
            })
            .then(data => {
              if (data.success) {
                // Redirect to the verification page with user_id
                window.location.href = `../verify/${data.user_id}`;
              } else {
                if (data.errors.username && data.errors.username.length > 0) {
                  errorMessage.value = data.errors.username[0].message;
                } else {
                  errorMessage.value = 'An unknown error occurred';
                }
                showError.value = true;
              }
            })
            .catch(error => {
              // Handle errors
              console.error('There was a problem with the fetch operation:', error);
            });
      } else {
        // Set error message and display it
        errorMessage.value = 'You must agree to the terms and conditions';
        showError.value = true;
      }
    };

    return {
      formData,
      handleSubmit,
      showError,
      errorMessage
    };
  }
};
</script>
<style>
.form-error {
  display: block;
  color: red;
  margin-top: 10px;
}

.sign-bg {
  background: url(./Signup-image/Sign-up-page.webp);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 0;

}

.sign {
  width: 420px;
}

.sign-logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
}

.sign-title {
  color: #1E1E1E;
  margin-bottom: 48px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 133.333% */
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

.sign-title span {
  color: #F99F00;
}

.signup {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 10px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  width: 100%;
  margin-bottom: 36px;
}

.signup input {
  border: none;
  outline: none;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 100% */
  letter-spacing: 0.42px;
  background: transparent;
  padding: 10px 0;
  width: 100%;
}

.remember-forget {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 12px;
}

.remember h4 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.sign-btn {
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  padding: 14px;
  margin-bottom: 40px;
}

.sign-btn input {
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  letter-spacing: 0.54px;
  border: none;
  outline: none;
  background: transparent;
}

.any-account {
  margin-bottom: 60px;
}

.any-account h1 {
  color: #494949;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 14px;
  /* 100% */
  margin-bottom: 0;
}

.any-account h1 span {
  color: #FF6B00;
}

.keebabi-footer {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  /* 85.714% */
  letter-spacing: 2.8px;
  margin-bottom: 0;
}

.keebabi-footer span {
  color: #691209;
}

@media only screen and (max-width: 576px) {
  .signup, .sign-btn, .remember-forget {
    width: 80% !important;
    margin: 0 auto !important;
  }

}

</style>