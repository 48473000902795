<template>
  <div class="login-bg">
    <div class="login-bg-img">
      <img src="./Login-image/Log-in-bg.png" alt="">

      <div class="login">
        <div class="login-logo">
          <img src="./Login-image/Login-logo.png" alt="">
        </div>
        <h1 class="login-title">Forgot Password</h1>
        <p class="login-p">Select which contact details should we use <br> to reset your password</p>
        <div class="form-error" v-if="showError">{{ errorMessage }}</div>
        <form @submit.prevent="handleNext" action="" class="form-forgot">
          <div class="login-address">

            <input type="text" v-model="phone_number" id="phone-number" placeholder="Enter your phone number">
          </div>


          <input class="login-btn" type="submit" value="Next">


        </form>
        <p class="keebabi-footer">Thank you for choosing <span>Keebabi!</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from "vue";

export default {
  data() {
    return {
      phone_number: ''
    };
  },
  methods: {
    async handleNext() {
      const formData = new FormData();
      formData.append('phone_number', this.phone_number);
      try {
        const response = await fetch("/send-code-forget-password/", {
          method: 'POST',
          headers: {
            'X-CSRFToken': this.getCookie('csrftoken')
          },
          body: formData
        });

        if (response.ok) {
          const data = await response.json();
          console.log(response)
          if (data.success) {
            // Redirect to another page on successful login
            window.location.href = '/verify-code-forget-password/'+data.user+'/'; // Change this to your desired URL
          } else {
            // Handle login failure (e.g., show error message)
            this.showError = true;
            this.errorMessage = 'Phone number is incorrect or try later.';
          }
        } else {
          this.showError = true;
          this.errorMessage = 'Phone number is incorrect or try later.';
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  },
  setup() {
    const showError = ref(false);
    const errorMessage = ref('');

    return {
      showError,
      errorMessage
    };
  }
};
</script>
<style scoped>
.form-error {
  display: block;
  color: red;
  margin-top: 10px;
}
.login-bg-img {
  width: 100%;
  position: relative;

}

.login-bg-img img {
  width: 100%;
  height: 932px;
}

.login {
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  transform: translate(0%, -178%);
}

form {
  width: 420px;
  margin: 0 auto;
}

.login-logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
}

.login-title {
  color: #1E1E1E;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 133.333% */
  letter-spacing: 1.8px;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.login-title span {
  color: #F99F00;
}

.login-address {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 10px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  width: 100%;
  margin-bottom: 36px;
}

.login-pass {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 10px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  width: 100%;
  margin-bottom: 36px;
}

.login-address input {
  border: none;
  outline: none;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  background: transparent;
  padding: 10px 0;
  width: 100%;
}

.login-pass input {
  border: none;
  outline: none;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  background: transparent;
  padding: 10px 0;
  width: 100%;
}

.remember h4 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.login-btn {
  width: 100%;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  padding: 14px;
  margin-bottom: 40px;
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.54px;

}

.any-account {
  margin-bottom: 60px;
}

.any-account h1 {
  color: #494949;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  margin-bottom: 0;
}

.any-account h1 span {
  color: #FF6B00;
}

.keebabi-footer {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 85.714% */
  letter-spacing: 2.8px;
  margin-bottom: 0;
}

.keebabi-footer span {
  color: #691209;
}

.login-p {
  margin-bottom: 48px;
}

.form-forgot {
  margin-bottom: 24px;
}
</style>